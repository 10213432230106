<template>
  <div class="q-px-md">
    <q-card>
      <q-card-section class="row border-bottom items-center full-width q-py-xs">
        <h5 class="q-my-none">
          {{ $t('Products') }}
        </h5>

        <search
            dense
            is-expandable
            @submit="handleSearch"
        />

        <q-space/>

        <q-btn
            :color="serverParams.filter && serverParams.filter.length > 0 ? 'light-blue-9' : 'dark'"
            text-color="white"
            size="sm"
            class="q-mr-sm"
            :label="filterBtnText"
            no-caps
            unelevated
            @click="toggleFilters"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <filter-collapse
            :is-open="isOpenFilters"
            :options="{
            defaultFilter: serverParams.filter,
            fields: activatedFields,
            values: {
              states: states
            },
            style: {
              noGroups: true
            }
          }"
            @submit="handleFiltersSubmit"
            @close="toggleFilters"
        />
      </q-card-section>

      <q-card-section class="q-ma-none q-pa-none">
        <q-table
            row-key="id"
            :rows-per-page-label="$t('Rows per page')"
            :rows="items"
            :columns="columns"
            v-model:pagination="pagination"
            :loading="isLoading"
            :filter="filter"
            virtual-scroll
            binary-state-sort
            flat
            @request="onRequest"
        >
          <template v-slot:loading>
            <q-inner-loading
                showing
                color="primary"
            />
          </template>

          <template v-slot:body="props">
            <q-tr
                :props="props"
                class="clickable"
            >
              <q-td
                  key="image"
                  :props="props"
                  auto-width
              >
                <img
                    :src="props.row.image ? props.row.image : fallbackImage"
                    style="width: 80px; height: 70px; object-fit: contain;"
                    @error="onImageLoadFailure"
                >

                <div class="text-center">
                  <q-btn
                      :label="$t(props.row._embedded?.productOffer?.type)"
                      color="light-blue-9"
                      icon-right="visibility"
                      size="sm"
                      class="q-mt-sm block full-width text-capitalize"
                      no-caps
                      style="min-width: 120px;"
                      @click="handleShowInfo(props.row)"
                  />
                </div>
              </q-td>

              <q-td
                  key="id"
                  :props="props"
                  style="max-width: 120px; word-wrap: break-word; white-space: normal !important;"
              >
                <div class="text-subtitle1">
                  {{ `${props.row.name} (${props.row.id})` }}
                </div>

                <div class="text-caption">{{ props.row.article }}</div>
              </q-td>

              <q-td
                  key="state"
                  :props="props"
                  auto-width
              >
                <q-badge
                    :color="stateColors[props.row.state]"
                    text-color="dark"
                    class="q-pa-sm"
                >
                  {{ $t(stateName[props.row.state] || props.row.state) }}
                </q-badge>
              </q-td>

              <q-td
                  key="reserve"
                  :props="props"
                  auto-width
              >
                <div v-if="props.row._embedded && props.row._embedded.reserve">
                  {{ props.row._embedded.reserve.id }}
                </div>
              </q-td>

              <q-td
                  key="shop"
                  :props="props"
                  auto-width
              >
                <div v-if="props.row._embedded && props.row._embedded.shop">
                  {{ props.row._embedded.shop.name }}
                </div>
              </q-td>

              <q-td
                  key="price"
                  :props="props"
                  auto-width
              >
                <strong v-if="!props.row.price">
                  -
                </strong>

                <strong v-else>
                  {{ props.row.price }}
                </strong>
              </q-td>

              <q-td
                  key="count"
                  :props="props"
                  auto-width
              >
                {{ props.row.count }}
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </q-card-section>
    </q-card>

    <offer-modal ref="offerModal"/>
  </div>
</template>

<script>
// Utils
import { buildQuery } from '../../utils/query-utils'
import FilterCollapse from '../filters/FilterCollapse.vue'

// Components
import OfferModal from '../modals/OfferModal.vue'
import Search from '../search/Search.vue'

export default {
  name: 'StoragePlaceProducts',
  components: {
    OfferModal,
    Search,
    FilterCollapse
  },
  props: {
    place: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      isOpenFilters: false,
      fallbackImage: 'assets/img/fallback-image/package.png',
      stateName: {
        normal: 'On hand',
        booked: 'Booked',
        blocked: 'Blocked',
        new: 'Received'
      },
      stateColors: {
        new: 'grey-5',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green',
        normal: 'success',
        expected: 'green',
        missing: 'grey'
      },
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'left'
        },
        {
          label: this.$t('Details'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left'
        },
        {
          label: this.$t('Reserve'),
          name: 'reserve',
          align: 'left'
        },
        {
          label: this.$t('Store'),
          name: 'shop',
          align: 'left'
        },
        {
          label: this.$t('Price'),
          name: 'price',
          align: 'left'
        },
        {
          label: this.$t('Quantity'),
          name: 'count',
          align: 'center'
        }
      ],
      isLoading: false,
      items: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      filter: '',
      search: '',
      serverParams: {
        filter: []
      },
      states: [
        { id: 'normal', title: 'normal' },
        { id: 'expected', title: 'expected' },
        { id: 'new', title: 'new' },
        { id: 'defected', title: 'defected' },
        { id: 'shipped', title: 'shipped' },
        { id: 'returned', title: 'returned' },
        { id: 'delivered', title: 'delivered' },
        { id: 'blocked', title: 'blocked' },
        { id: 'booked', title: 'booked' }
      ],
      activatedFields: [
        'shop',
        'state'
      ]
    }
  },
  computed: {
    filters () {
      const actions = {
        gte: filter => ({ from: filter.value, to: null }),
        lte: filter => ({ from: null, to: filter.value }),
        between: filter => ({ from: filter.from, to: filter.to }),
        in: filter => (filter.values.map(id => this.states.find(x => x.id === id)))
      }
      return (this.serverParams.filter || []).reduce((acc, filter) => {
        const currFilterValue = typeof actions[filter.type] === 'function'
            ? actions[filter.type](filter)
            : filter.value

        return {
          ...acc,
          [filter.field]: currFilterValue
        }
      }, {})
    },
    filterBtnText () {
      return this.serverParams.filter && this.serverParams.filter.length > 0
          ? this.$t('Filtered: ') + this.pagination.rowsNumber
          : this.$t('Filter')
    }
  },
  mounted () {
    this.onRequest()
  },
  methods: {
    handleFiltersSubmit (filter) {
      this.isOpenFilters = false
      return this.onRequest({ pagination: { filter, per: 1 } })
    },
    toggleFilters () {
      this.isOpenFilters = !this.isOpenFilters
    },
    handleSearch (search) {
      this.search = search
      return this.onRequest({ pagination: { per_page: 25, page: 1 } })
    },
    handleShowInfo (item) {
      this.$refs.offerModal.open({ ...item._embedded.productOffer, _embedded: { shop: item._embedded.shop } })
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    },
    onRequest (data = {}) {
      this.isLoading = true
      this.pagination = data.pagination || {}
      const query = buildQuery(this.pagination)

      query.search = this.search

      if (!query.filter) {
        query.filter = []
      }

      if (!query.per_page) {
        query.per_page = 25
      }

      if (this.place && !query.filter.find(x => x.field === 'place')) {
        query.filter.push({ type: 'eq', field: 'place', value: this.place.id })
      }

      query.group = [{ field: 'productOffer', alias: 'i' }, { field: 'reserve', alias: 'i' }, { field: 'state', alias: 'i' }]

      return this.$service.storageItemEntity.getAll(query, this.place && this.place.id)
          .then(({ items, totalPages, page, totalItems }) => {
            this.pagination = {
              ...this.pagination,
              page,
              rowsNumber: totalItems
            }

            this.items = items.map(x => ({ ...x._embedded.productOffer, ...x }))

            return { items, totalPages, page, totalItems }
          })
          .finally(() => {
            this.isLoading = false
          })
    }
  }
}
</script>
